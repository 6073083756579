<template>
  <div id="rebate">
    <div class="calendar-content-top">
      <div class="left clearfix">
        <!-- <div class="container-left-top">
          <span
            >{{ $t('home.RebateAccountNumber') }}&nbsp;&nbsp; <span class="show-message">{{ accountID }}</span></span
          >
        </div> -->
        <div class="bottom">
          <li class="container-left">
            <div class="container-left-bottom" v-if="fundsPermission">
              <p v-textOverflow>
                <span>{{ currAccountCurrency | currencySymbol }}</span>
                {{ totalBalance | currency('') }}
                <span class="icon">
                  <img src="@/assets/images/label_icon.png" alt class="label-icon" />
                  <i18n path="home.HOVER" tag="hover"> </i18n
                ></span>
              </p>
              <span>{{ $t('home.totalCommission') }}</span>
            </div>
            <div v-else>***</div>
          </li>
          <li class="container-left-right">
            <el-button
              v-if="!fundsPermission"
              disabled
              class="el-button btn-blue"
              to=""
              @click.native="applyRebate"
              data-testid="applyRebate"
            >
              {{ $t('home.APPLY_FOR_A_REBATE') }}
            </el-button>
            <router-link v-else class="el-button btn-blue" to="" @click.native="applyRebate" data-testid="applyRebate">
              {{ $t('home.APPLY_FOR_A_REBATE') }}
            </router-link>
          </li>
        </div>
      </div>
      <div class="right clearfix">
        <!-- <div class="container-left-top">
          <span
            >{{ $t('home.RebateAccountNumber') }}&nbsp;&nbsp;
            <span class="show-message">{{ accountID }}</span>
          </span>
        </div> -->
        <div class="bottom">
          <li class="container-left">
            <div class="container-left-bottom" v-if="fundsPermission">
              <p v-textOverflow>
                <span>{{ currAccountCurrency | currencySymbol }}</span>
                {{ availableBalance | currency('') }}
              </p>
              <span>{{ $t('home.availableBalance') }}</span>
            </div>
            <div v-else>***</div>
          </li>
          <li class="container-right-right">
            <!-- 路由跳转 -->
            <el-button
              :disabled="!fundsPermission"
              @click="goToRebateWithdraw()"
              class="el-button btn-blue"
              data-testid="WITHDRAW_REBATE"
            >
              {{ $t('home.WITHDRAW_REBATE') }}
            </el-button>
            <el-button
              :disabled="!fundsPermission"
              @click="goToRebateTransfer()"
              class="el-button btn-blue"
              data-testid="TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT"
            >
              {{ $t('home.TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT') }}
            </el-button>
          </li>
        </div>
      </div>
      <!-- apply for rebate 弹窗 -->
      <el-dialog :visible.sync="dialogVisible" :show-close="false" top="0">
        <img src="@/assets/images/dialog_success.png" alt="" class="true" />

        <p>
          {{ $tc('home.applyRebateSuccessTip', 0) }}<br />
          <span v-html="$tc('home.applyRebateSuccessTip', 1, { amount: appliedCommission, account: accountID })"></span>
          {{ $tc('home.applyRebateSuccessTip', 2) }}
        </p>
      </el-dialog>

      <!-- <el-dialog
        class="withdraw_dialog"
        :title="$t('common.withdrawChannel.limitTitle')"
        :visible.sync="visible"
        width="400px"
        center
        :show-close="true"
      >
        <div style="text-align: center">
          {{
            fromFunctionCode == 'updatePassword'
              ? $t('common.withdrawChannel.limitPassword', { day: this.day, hour: this.hour })
              : fromFunctionCode == 'changeAuthenticator' ? $t('common.withdrawChannel.limitSecurityAuthenticator', { day: this.day, hour: this.hour })
            : $t('common.withdrawChannel.limitUserLogin', { day: this.day, hour: this.hour })
          }}
        </div>
        <div slot="footer" class="dialog-footer btn-box">
          <el-button style="width: 100%" @click="visible = false" class="btn-blue">{{
            $t('common.button.iKnow')
          }}</el-button>
        </div>
      </el-dialog> -->
    </div>
    <div class="calender-content-bottom" v-if="isShowProfit">
      <span>
        {{ $t('home.PROFIT') }}
        <span class="icon">
          <img src="@/assets/images/label_icon.png" alt class="label-icon" />
          <i18n path="home.ACCOUNTS_PROFIT" tag="hover"></i18n>
        </span>
      </span>
      <div class="container-left" v-if="accountDataPermission">
        <div class="profit-ongoing" v-if="iconLoad === 'ongoing'">
          <img src="@/assets/images/profitLoading.gif" alt="" />
        </div>
        <div class="profit-error" v-else-if="iconLoad === 'error'" @click="getGetNextLevelTradingAccountProfit">
          {{ $t('home.reload') }} <i class="el-icon-refresh-right"></i>
        </div>
        <div class="profit" v-else>
          <span>{{ profitCurrAccountCurrency | currencySymbol }}</span>
          {{ profit | currency('') }}
        </div>
      </div>
      <div class="container-left" v-else>***</div>
    </div>
  </div>
</template>

<script>
import Hover from '@/components/Hover';
import {
  apiQueryAvailableBalance,
  apiApplyCommission,
  apiQueryRebatesBlackList,
  apiGetSecuritys,
  apiGetNextLevelTradingAccountProfit
} from '@/resource';
import blackList from '@/mixins/blackList';

export default {
  name: 'rebate',
  components: { Hover },
  mixins: [blackList],
  props: { userID: '', isShowProfit: false },
  data() {
    return {
      day: '',
      hour: '',
      visible: false,
      fromFunctionCode: '',
      dialogVisible: false,
      appliedCommission: 0,
      availableBalance: 0,
      lang: this.$store.state.common.lang,
      profit: 0,
      profitCurrAccountCurrency: '',
      iconLoad: 'ongoing' // 默认设置iconLoad为‘ongoing’ , ongoing 进行中 ，error： 失败
    };
  },
  computed: {
    fundsPermission() {
      return this.$store.state.common.permissionIds.includes(3) || !this.$store.state.common.isSubUser;
    },
    accountDataPermission() {
      return this.$store.state.common.permissionIds.includes(1) || !this.$store.state.common.isSubUser;
    },
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    totalBalance: {
      get() {
        return this.fundsPermission ? this.$store.state.behavior.balance : 0;
      },
      set(value) {
        return this.$store.commit('behavior/setBalance', value);
      }
    }
  },
  methods: {
    getGetNextLevelTradingAccountProfit() {
      if (!this.accountID) return;
      const startTime = new Date();
      apiGetNextLevelTradingAccountProfit(this.accountID)
        .then(resp => {
          if (resp.data.code === 0)
            if (this.accountDataPermission) {
              this.profit = resp.data.data.result;
              this.profitCurrAccountCurrency = resp.data.data.currency;
            } else {
              this.profit = 0;
              this.profitCurrAccountCurrency = '';
            }
          const endTime = new Date();
          const executionTime = endTime - startTime;
          console.log(`代码执行时间：${executionTime} 毫秒`);
          this.iconLoad = ''; // 加载完成，设置 iconLoad 为 ''
        })
        .catch(error => {
          console.log('errorsxl--->', error);
          this.iconLoad = 'error'; // 加载完成，设置 iconLoad 为 ''
        });
    },
    // async getSecuritys() {
    //   await apiGetSecuritys().then(res => {
    //     if (res.data.code == 0 && res.data.data.length > 0) {
    //       let data = res.data.data;
    //       let limitObj = data.find(el => el.functionCode == 'withdraw');
    //       this.fromFunctionCode = limitObj.fromFunctionCode;
    //       this.day = 'T + ' + limitObj.limitDayNumber;
    //       this.hour = limitObj.lastHours;
    //       this.visible = true;
    //     }
    //   });
    // },
    // 获取 可用余额
    queryAvailableBalance() {
      if (!this.accountID) return;
      apiQueryAvailableBalance({
        qUserId: this.userID,
        qAccount: this.accountID
      }).then(resp => {
        if (resp.data.code === 0)
          this.fundsPermission
            ? (this.availableBalance = resp.data.data.availableBalance)
            : (this.availableBalance = 0);
      }, this.$handleError);
    },
    // 申请佣金
    async applyRebate() {
      if (!this.accountID) return;

      let result = await apiQueryRebatesBlackList();
      if ((result.data.data.list.filter(item => item.login === this.accountID)[0] || []).isCommissionProhibit) {
        this.$message({
          type: 'error',
          message: this.$t('home.blacklist')
        });
        return;
      }

      apiApplyCommission({
        qAccount: this.accountID,
        qUserId: this.userID
      }).then(resp => {
        if (resp.data.code === 0) {
          this.appliedCommission = this.$options.filters.currency(this.totalBalance, '');
          this.dialogVisible = true;
          this.$parent.$refs.accountNumber.rebatesList.map((item, idx) => {
            if (item.login === this.accountID) item.commission = 0;
          });
          this.totalBalance = 0;
          //save for passing new commission among different pages
          this.bus.$emit('updateRebateHistory', true);
        }
      }, this.$handleError);
    },
    // 内容初始化
    init() {
      this.queryAvailableBalance();
      // 首页加载 客户损益
      if (this.isShowProfit) this.getGetNextLevelTradingAccountProfit();
      this.$emit('accountIdAndCurrency', this.accountID, this.currAccountCurrency);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/rebate.scss';
@import '@/assets/css/components/vDialog.scss';
.withdraw_dialog {
  /deep/ .el-dialog__header {
    padding: 61px 61px 0 61px;
  }
  /deep/ .el-dialog__body {
    padding: 0 61px;
    margin-top: 42px;
    margin-bottom: 48px;
  }
  /deep/ .el-dialog__footer {
    padding: 0 61px 61px 61px;
  }
  /deep/ .el-dialog__headerbtn {
    display: inline-block;
    font-size: 20px;
    .el-dialog__close {
      font-weight: bolder;
      color: $black;
    }
  }
  .word_break {
    word-break: normal;
  }
}
</style>
